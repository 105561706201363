<template>
  <div class="nj-grid">
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Configuração de Notificação"
      text="Deseja realmente excluir a configuração de notificação?"
    />
    <div class="header">
      <div class="title">
        <v-icon>mdi-shield-account-outline</v-icon>
        {{ $route.meta.title || $route.name }}
      </div>
      <v-btn
        icon
        title="Retornar"
        class="align-self-baseline"
        :to="{ name: 'NotificationSettings' }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div class="ng-grid-body">
      <Form :disabled="true">
        <div class="form-actions">
          <v-btn class="cancel" elevation="0" @click.prevent="remove()">
            <v-icon>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            :to="{
              name: 'NotificationSettingEdit',
              params: { id: this.$route.params.id },
            }"
          >
            <v-icon>mdi-clipboard-edit-outline</v-icon>
            Editar
          </v-btn>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import Form from './form'

export default {
  name: 'NotificationSettingShow',
  components: { Form },

  methods: {
    remove() {
      if (!this.$hasPermission('naja_app_roles_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.notificationSettings
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({
                name: 'NotificationSettings',
                query: { refresh: true },
              })
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 500) {
                  this.$root.$children[0].toast(
                    'Erro ao remover configuração de notificação',
                    'error'
                  )
                } else {
                  this.$root.$children[0].toast(
                    error.response.data.error,
                    'error'
                  )
                }
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.toggleLoading()
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },
  },
}
</script>
